import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const LearnMore = () => {
  return (
    <Container>
      <h1 className="my-5">Learn More About Sustainable Living</h1>
      <Row>
        <Col>
          <h2>What is Sustainable Living?</h2>
          <p>
            Sustainable living refers to a lifestyle that aims to reduce an
            individual's or society's use of natural resources and minimize
            their impact on the environment. By making conscious choices in
            everyday life, people can reduce waste, conserve energy, and
            support the long-term health of the planet.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>Benefits of Sustainable Living</h2>
          <ul>
            <li>Reduced environmental impact</li>
            <li>Improved health and well-being</li>
            <li>Decreased energy and water consumption</li>
            <li>Lower living costs</li>
            <li>Support for local economies</li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>How to Get Started with Sustainable Living</h2>
          <p>
            Transitioning to a more sustainable lifestyle can be achieved
            through a variety of small changes in daily habits. Some ideas
            include:
          </p>
          <ul>
            <li>Using energy-efficient appliances and light bulbs</li>
            <li>Reducing water waste by fixing leaks and using low-flow fixtures</li>
            <li>Composting food scraps and other organic waste</li>
            <li>Opting for reusable products over single-use items</li>
            <li>Supporting local and eco-friendly businesses</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default LearnMore;
