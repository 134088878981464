import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const blogPosts = [
    {
        title: '10 Simple Ways to Reduce Plastic Waste',
        date: 'March 29, 2023',
        author: 'Dkprgrmmr',
        content: `Introduction:
        Plastic waste is a significant issue that affects the environment, wildlife, and human health. With millions of tons of plastic waste generated annually, it's essential to find effective ways to reduce our plastic consumption. Here are ten simple ways you can help reduce plastic waste and contribute to a cleaner, healthier planet.
        
        1. Use Reusable Bags:
        Say no to single-use plastic bags and invest in reusable bags for grocery shopping and other purchases. Keep a few reusable bags in your car, purse, or backpack to ensure you always have one available when needed.
        
        2. Avoid Plastic Packaging:
        Choose products with minimal or no plastic packaging whenever possible. Opt for loose fruits and vegetables over pre-packaged ones, and consider purchasing items in bulk to reduce plastic waste.
        
        3. Use a Reusable Water Bottle:
        By using a reusable water bottle, you can reduce your plastic consumption and save money. Refill your bottle from the tap or a water fountain, and avoid buying single-use plastic water bottles.
        
        4. Bring Your Own Reusable Cup:
        Many coffee shops and restaurants now offer discounts for customers who bring their own reusable cups. Not only will you help reduce plastic waste, but you'll also save money in the long run.
        
        5. Say No to Plastic Straws:
        If you don't need a straw, don't use one. If you do need one, consider investing in a reusable metal or silicone straw that you can carry with you and use instead of single-use plastic straws.
        
        6. Use Reusable Food Containers and Wraps:
        Replace single-use plastic containers, plastic wrap, and sandwich bags with reusable alternatives, such as glass containers, silicone food storage bags, and beeswax wraps.
        
        7. Shop Responsibly:
        Support companies that prioritize sustainability and eco-friendly practices. Look for products made from recycled materials or those with minimal packaging.
        
        8.Recycle Properly:
        If you must use plastic, ensure that you recycle it properly. Familiarize yourself with local recycling guidelines and make sure to clean and sort your recyclables according to the rules.
        
        9. Participate in Clean-up Events:
        Join local community clean-up events to help remove plastic waste from parks, beaches, and other public spaces. By working together, we can make a significant impact on reducing plastic pollution in our environment.
        
        10. Spread Awareness:
        Share your knowledge about the importance of reducing plastic waste with friends, family, and colleagues. Encourage them to adopt sustainable practices, and together we can make a difference.
        
        Conclusion:
        Reducing plastic waste is an achievable goal if everyone takes small steps to change their habits. By implementing these ten simple strategies, you can help minimize plastic pollution and contribute to a more sustainable and healthier planet for future generations. Remember, every effort counts, and together we can make a difference.`,
      },
      {
        title: 'Eco-Friendly Alternatives to Common Household Items',
        date: 'March 5, 2023',
        author: 'John Smith',
        content: `Introduction:
        As we become more aware of the environmental impact of our daily choices, it's essential to find eco-friendly alternatives to common household items. Making these sustainable swaps can help reduce waste, decrease carbon emissions, and protect our planet. Here are some eco-friendly alternatives to consider incorporating into your daily routine.
        
        Reusable Grocery Bags:
        Replace single-use plastic bags with reusable grocery bags made from cloth, canvas, or other sustainable materials. Keep them in your car or near your front door to ensure they're always handy when you need them.
        
        Beeswax Wraps:
        Swap out plastic wrap and aluminum foil for beeswax wraps, a reusable and biodegradable alternative. Beeswax wraps can be used to cover bowls, wrap sandwiches, or store leftovers, and they can last up to a year with proper care.
        
        Cloth Napkins and Towels:
        Reduce your paper waste by using cloth napkins and towels instead of disposable paper towels and napkins. Simply toss them in the wash when they get dirty, and you'll have an eco-friendly alternative that can save you money over time.
        
        Silicone Food Storage Bags:
        Replace single-use plastic bags with reusable silicone food storage bags. They're perfect for storing leftovers, packing lunches, and even cooking sous-vide. Plus, they're easy to clean and dishwasher-safe.
        
        Reusable Coffee Filters:
        If you're a coffee lover, switch to reusable coffee filters made from metal, cloth, or other sustainable materials. Not only will you reduce waste, but you may also notice an improvement in the taste of your coffee due to better filtration.
    
        Bamboo Toothbrushes:
        Switch from plastic toothbrushes to eco-friendly alternatives made from bamboo. Bamboo toothbrushes are biodegradable and can be composted once they've reached the end of their life, reducing plastic waste in landfills.
        
        LED Light Bulbs:
        Replace traditional incandescent light bulbs with energy-efficient LED light bulbs. Although the initial cost may be higher, LED bulbs last longer and use significantly less energy, saving you money on your electricity bill and reducing your carbon footprint.
        
        Solar-Powered Chargers:
        Consider using solar-powered chargers for your electronic devices. By harnessing the power of the sun, you can reduce your reliance on non-renewable energy sources and decrease your carbon emissions.
        
        Compostable Trash Bags:
        Swap out traditional plastic trash bags for compostable alternatives. These eco-friendly bags break down more quickly than regular plastic bags, helping to reduce landfill waste.
        
        Natural Cleaning Products:
        Replace harsh chemical cleaning products with eco-friendly alternatives made from natural ingredients. Many conventional cleaning products contain harmful chemicals that can pollute waterways and harm aquatic life. By choosing environmentally friendly cleaning products, you can help protect our planet while still keeping your home clean and fresh.
        
        Conclusion:
        Incorporating eco-friendly alternatives to common household items is a simple and effective way to reduce your environmental impact. By making these sustainable swaps, you can contribute to a cleaner, greener planet for future generations. Remember, every small change adds up, and together we can make a difference in preserving our environment.`,
      }];

const Blog = () => {
  const [expandedPost, setExpandedPost] = useState(null);

  const handleExpand = (index) => {
    if (expandedPost === index) {
      setExpandedPost(null);
    } else {
      setExpandedPost(index);
    }
  };

  return (
    <Container>
      <h1 className="my-5">Blog</h1>
      {blogPosts.map((post, index) => (
        <Row key={index} className={index !== 0 ? 'mt-5' : ''}>
          <Col>
            <Card onClick={() => handleExpand(index)}>
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {post.date} | {post.author}
                </Card.Subtitle>
                <Card.Text>
                  {expandedPost === index ? post.content : `${post.content.substring(0, 100)}...`}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Blog;
