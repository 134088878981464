import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Container,
  Navbar,
  Nav,
  Row,
  Col
   }   from 'react-bootstrap';
import LearnMore from './components/LearnMore';
import Blog from './components/Blog';
import Login from './components/Login';
import Home from './components/Home';


const App = () => {
  return (
    <Router>
      <div className="App">
        <header>
          <Navbar bg="success opacity-50" variant="dark" expand="lg">
            <Container>
            <Navbar.Brand href="/">Eco Friendly Explorer</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/blog">Blog</Nav.Link>
                  <Nav.Link href="/learn-more">About</Nav.Link>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn-more" element={<LearnMore />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/login" element={<Login />} />
        </Routes>  
        <footer className="bg-success bg-opacity-50 py-4">
          <Container>
            <Row>
              <Col>
                <p className="text-muted">
                  &copy; 2023 EcoFriendlyExplorer.com. All rights reserved.
                </p>
              </Col>
              <Col className="d-flex justify-content-end">
                <Nav className="footer-nav">
                  <Nav.Link href="/components/home">Home</Nav.Link>
                  <Nav.Link href="/blog">Blog</Nav.Link>
                  <Nav.Link href="/learn-more">Learn More</Nav.Link>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                  <Nav.Link href="/login">Log In</Nav.Link>

                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>      
      </div>
    </Router>
  );
};

export default App;
