import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Container,
    Button,
    Card,
    Row,
    Col,
    CardGroup,
  } from 'react-bootstrap';

const Home = () => {
    return (
      <>
      <main>
        <Container className="my-5">
            <h1>Welcome to EcoFriendlyExplorer.com</h1>
            <p>
              Discover the latest eco-friendly products and sustainable living
              tips to make a positive impact on our environment.
            </p>
            <p>
              <Button variant="success" size="lg" as="a" href="/learn-more">
                Learn More
              </Button>
            </p>
        </Container>
        <Container>
          <h2 className="mb-4">Featured Topics</h2>
          <CardGroup>
            <Row>
            <Col sm={4}>
                    <Card className="mb-4">
                      <Card.Img
                        variant="top"
                        src={require("./organic100.png")}
                      />
                      <Card.Body>
                        <Card.Title>Eco-Friendly Products</Card.Title>
                        <Card.Text>
                          Find the best eco-friendly products that are both
                          sustainable and effective.
                        </Card.Text>
                        <Button variant="success">Read More</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card className="mb-4">
                      <Card.Img
                        variant="top"
                        src="https://via.placeholder.com/300x200"
                      />
                      <Card.Body>
                        <Card.Title>Sustainable Living Tips</Card.Title>
                        <Card.Text>
                          Learn how to live sustainably and reduce your carbon
                          footprint.
                        </Card.Text>
                        <Button variant="success">Read More</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={4}>
                    <Card className="mb-4">
                      <Card.Img
                        variant="top"
                        src="https://via.placeholder.com/300x200"
                      />
                      <Card.Body>
                        <Card.Title>Green Home Ideas</Card.Title>
                        <Card.Text>
                          Explore innovative green home ideas and eco-friendly
                          design concepts.
                        </Card.Text>
                        <Button variant="success">Read More</Button>
                      </Card.Body>
                    </Card>
                  </Col>        </Row>
          </CardGroup>
        </Container>
      </main>
    </>
      )
    };

export default Home;