import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';

const ADMIN_PASSWORD = 'admin2023'; // Set your desired admin password

const Login = () => {
  const [password, setPassword] = useState('');
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) {
      alert('You are now logged in as administrator');
      // Implement any additional logic after successful login here
      history.push('/');
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <>
    <main>
    <Container>
      <h1 className="my-5">Login</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </Container>
    </main>
    </>
  );
};

export default Login;
